<template>
  <div class="container">
    <PublicHeader
      :totalName="$t('moneyAddr').title"
      :routerName="routerName"
      :query="query"
    />
    <div class="content">
      <div
        class="item"
        v-for="item in list"
        :key="item.Id"
        @click="handleClick(item)"
      >
        <img :src="item.QRCode" alt="" />
        <div class="info">
          <div>{{ $t('moneyAddr').Currency }}： {{ item.Type }}</div>
          <div v-if="item.Type === 'USDT'">
            {{ $t('moneyAddr').ChainAddress }}： {{ item.CurrencyUrlType }}
          </div>
          <div class="addr">
            <p>{{ $t('moneyAddr').Address }}：</p>
            <p>
              {{ item.CurrencyUrl }}
            </p>
          </div>
          <div>
            {{ $t('moneyAddr').Date }}： {{ item.CreateTime | getLocalTime }}
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="$router.push({ name: 'MoneyAddrCreate' })">
      {{ $t('moneyAddr').add }}
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import transactionApi from '@/api/transaction'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      list: [],
      selectMode: false,
      routerName: '',
      query: {},
      form: {
        psge: 1,
        size: 50,
        type: null
      }
    }
  },
  mounted() {
    if (this.$route.query.select) {
      this.selectMode = true
      this.form.type = this.$route.query.type
      this.routerName = 'WithForm'
      this.query = this.$route.query
    }
    this.getList()
  },
  methods: {
    handleClick(item) {
      if (this.selectMode === true) {
        this.$router.push({
          name: 'WithForm',
          query: { info: item, type: this.$route.query.type }
        })
      } else {
        this.$router.push({ name: 'MoneyAddrCreate', query: { info: item } })
      }
    },
    async getList() {
      const res = await transactionApi.WalletAddress(this.form)
      this.list = res.Data
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 44px 0 70px;
  min-height: 100vh;
  .content {
    height: calc(100vh - 116px);
    overflow-y: auto;
    .item {
      padding: 15px;
      margin: 10px 15px;
      border-radius: 4px;
      background: #17181e;
      display: flex;
      align-items: center;
      img {
        width: 100px;
        height: 100px;
      }
      .info {
        flex: 1;
        padding: 0 10px;
        div {
          margin: 10px 0;
        }
        .addr {
          display: flex;
          p:nth-of-type(1) {
            width: auto;
          }
          p:nth-of-type(2) {
            flex: 1;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94vw;
    height: 40px;
    border-radius: 20px;
    background: #8e1473;
    color: #fff;
    position: fixed;
    bottom: 20px;
    left: 3vw;
  }
}
</style>
